.aboutMeDiv{
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 5%;
}

.aboutMeTextDiv{
    padding-top: 7%;
    color: black;
}

.aboutMePart1{
    font-size: 100%;
}

.aboutMePart2{
    padding-top: 5%;
    font-size: 80%;
}

.profilePicture{
    border-radius: 50%;
    width: 17%;
    min-width: 30vh;
    margin-top: 5%;
}

#contactTextButton{
    background:none!important;
    border:none; 
    cursor: pointer;
    outline: none;    
    transition: 0.4s ease-in-out;
    font-family: "Montserrat";
}

#aboutHeader{
    font-family: "Montserrat";
    font-size: 250%;
    margin-bottom: 2%;
    color: grey;
    transition: 2s ease-in-out;
  }