.domainFinderDiv{
    height: 80vh;
    background-color: rgb(240, 240, 240);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inputDiv{
    padding-left: 15%;
    padding-right: 15%;
}

.outputDiv{
    background-color: rgb(230, 230, 230);
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 3%;
    height: 0;
}
#searchButton{
    margin-top: 1%;
}

#domainName{
    font-family: "Montserrat";
}