.ProjectsDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5vh;
}

.navSection{
    width:35vh;
    height:auto;
    position:relative;
    transition:.3s;
    margin:4px;
  }
  
.graderImage, .domainFinderImage, .sleepClockImage{
  width:35vh;
  height:auto;
  transition:.5s;
  border-radius:4px;
}

#graderNav:hover .graderImage{
  filter:blur(2px) brightness(60%);
}

#domainFinderNav:hover .domainFinderImage{
  filter:blur(2px) brightness(60%);
}

#sleepClockNav:hover .sleepClockImage{
  filter:blur(2px) brightness(60%);
}

.textSection{
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  position:absolute;
  z-index:1;
  top:15%;
  left:7.5%;
  right:7.5%;
  width:85%;
  text-align:center;
  color:#fff;
  opacity:0;
  transition:.3s;
}

.navSection h3{
  top:50%
}

.navSection:hover :not(img){
  opacity:1;
}

#projectsHeader{
  font-family: "Montserrat";
  font-size: 250%;
  margin-bottom: 2%;
  transition: 2s ease-in-out;
}

.projectList{
    padding-top: 5%;
}