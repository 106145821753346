#homeSectionButton:hover, #projectsSectionButton:hover, #aboutSectionButton:hover, #contactSectionButton:hover, #resumeNav:hover{
    color:brown;
}

#headerSection{
    z-index:1000;
    background-color: rgb(36, 39, 49);
    height: 6vh;
    width: 100%;
    font-family: "Montserrat";
    top: 0;
    position:sticky;
    display: flex;
    flex-direction: row;
}

.headerDiv{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 50%;
    height: 100%;
}

#homeSectionButton, #projectsSectionButton, #aboutSectionButton, #contactSectionButton, #resumeNav{
    background:none!important;
    border:none; 
    font-family: "Montserrat";
    cursor: pointer;
    padding-top: 1vh;
    padding: 2%;
    font-size: 2.5vh;
    outline: none;    
    transition: 0.4s ease-in-out;
    color: grey;
    white-space: nowrap;
}