.errorSection{
    background-color: rgb(36, 39, 49);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.errorMessage{
    font-family: "Montserrat";
    color: grey;
    font-size: 325%;
}

.errorPicture{
    height: 45vh;
}