.MainPage{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    justify-items: center;
}

#homeSection, .particles, #projectsSection, #aboutMeSection, #projectsSection{
    height: 100vh;
    box-sizing: border-box;
}

#homeSection, #contactSection{
    width: 100%;
}

#projectsSection, #aboutMeSection{
    width: 60%;
    min-width: 50vh;
    background-color:  rgb(248,248,248);
}

#homeSection{
    background-color:  rgb(36, 39, 49);
    font-family: "Montserrat";
}

#contactSection{
    background-color:  rgb(36, 39, 49);
    height: 80vh;
}



#startButton{
    background:none!important;
    border:none; 
    font-family: "Montserrat";
    cursor: pointer;
    padding-top: 1vh;
    padding: 2%;
    font-size: 2.5vh;
    outline: none;    
    transition: 0.4s ease-in-out;
    color: grey;
    white-space: nowrap;
}

#startButton:hover{
    color:brown;
    transform: scale(1.3);
}

