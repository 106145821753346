.contactSegment{
    display: flex;
    flex-direction: column;
    margin-left: 30%;
    margin-right: 30%;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    height: 100%;
}

#contactHeader{
    font-family: "Montserrat";
    font-size: 250%;
    color: grey;
    margin-bottom: 0;
    transition: 2s ease-in-out;
  }

#contactForm{
    width: 100%;
    min-width: 45vh;
}

#nameLabel, #emailLabel{
    font-family: "Montserrat";
    color: gray;
}

#contactTray{
    margin-bottom: 50%;
}