.UITop{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.UIMiddle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
  margin-bottom: 5%;
}


#modalTrigger{
  font-size: 75%;
  height: 75%;
}

#percToggleButton, #markToggleButton{
  height: 75%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#lightToggleButton, #darkToggleButton{
  max-width: 7vh;
}

.ViewToggleDiv{
  padding: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.UIComponents{
  display: flex;
}

.WeightMarkInputs{
  display: flex;
  flex-direction: row;
  margin-left: 15%;
}

#nameField{
  width: 150%;
}

#markField{
  margin-left: 5%;
}

#markField, #weightField{
  width: 100%;
}

#removeButton{
  height: 100%;
  margin-left: 5%;
}

.UISection{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


.InputSection{
  min-width: 50vh;
}

.OutputSection{
  display: flex;
  flex-direction: column;
  padding-right: 20vh;
  width: 45vh;
  min-width: 50vh;
}

#outputFields{
  font-size: 115%;
  margin-top: 0;
  padding-left: 5%;
}

.schemeSelectors{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

button:hover {
  color:red;
}

.progressBar{
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

#bar{
  margin-bottom: 0;
}

.InputOutputSections{
  min-height: 80vh;
  width: 100%;
  padding-left: 30vh;
  padding-top: 13vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-items: flex-end;
  justify-content: space-evenly;
}

.GraderDiv{
  display: flex;
  flex-direction: column;
}

#pavyonButton{
  max-width: 8vh;
  max-height: 5vh;
  font-size: 10px;
}