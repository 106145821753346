body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.FooterDiv{
    width: 100%;
    background-color: rgb(36, 39, 49);
    height: 20vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    bottom: 0;
}

.FooterInnerDiv{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;}

#gitIcon, #linkIcon, #instaIcon{
    width: 3vh;
    height: 3vh;
    min-height: 1vh;
    min-width: 1vh;
}

#gitNav, #linkNav, #instaNav{
    padding-left: 2vh;
    padding-right: 2vh;
}




#gitNav, #linkNav, #instaNav{
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    opacity: .7;
}

#gitNav:hover, #linkNav:hover, #instaNav:hover{
    visibility: visible;
    opacity: 1;
}

#footerTray{
    width: 25vh;
}

#footerStartButton{
    background:none!important;
    border:none; 
    font-family: "Montserrat";
    cursor: pointer;
    font-size: 150%;
    outline: none;    
    -webkit-transition: 0.4s ease-in-out;    
    transition: 0.4s ease-in-out;
    color: grey;
    white-space: nowrap;
    margin-bottom: 2vh;
}


#doubleUpIcon{
    margin-right: 0;
}

#homeNav{
    font-family: "Montserrat";
    cursor: pointer;
    font-size: 130%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    color: grey;
    padding-bottom: 2.5vh;
}

#homeNav:hover{
    color:brown;
}

#footerStartButton:hover{
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    color:brown;
}
.MainPage{
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    justify-items: center;
}

#homeSection, .particles, #projectsSection, #aboutMeSection, #projectsSection{
    height: 100vh;
    box-sizing: border-box;
}

#homeSection, #contactSection{
    width: 100%;
}

#projectsSection, #aboutMeSection{
    width: 60%;
    min-width: 50vh;
    background-color:  rgb(248,248,248);
}

#homeSection{
    background-color:  rgb(36, 39, 49);
    font-family: "Montserrat";
}

#contactSection{
    background-color:  rgb(36, 39, 49);
    height: 80vh;
}



#startButton{
    background:none!important;
    border:none; 
    font-family: "Montserrat";
    cursor: pointer;
    padding-top: 1vh;
    padding: 2%;
    font-size: 2.5vh;
    outline: none;    
    -webkit-transition: 0.4s ease-in-out;    
    transition: 0.4s ease-in-out;
    color: grey;
    white-space: nowrap;
}

#startButton:hover{
    color:brown;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}


.aboutMeDiv{
    font-family: "Montserrat";
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
    padding: 5%;
}

.aboutMeTextDiv{
    padding-top: 7%;
    color: black;
}

.aboutMePart1{
    font-size: 100%;
}

.aboutMePart2{
    padding-top: 5%;
    font-size: 80%;
}

.profilePicture{
    border-radius: 50%;
    width: 17%;
    min-width: 30vh;
    margin-top: 5%;
}

#contactTextButton{
    background:none!important;
    border:none; 
    cursor: pointer;
    outline: none;    
    -webkit-transition: 0.4s ease-in-out;    
    transition: 0.4s ease-in-out;
    font-family: "Montserrat";
}

#aboutHeader{
    font-family: "Montserrat";
    font-size: 250%;
    margin-bottom: 2%;
    color: grey;
    -webkit-transition: 2s ease-in-out;
    transition: 2s ease-in-out;
  }
.ProjectsDiv{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 5vh;
}

.navSection{
    width:35vh;
    height:auto;
    position:relative;
    -webkit-transition:.3s;
    transition:.3s;
    margin:4px;
  }
  
.graderImage, .domainFinderImage, .sleepClockImage{
  width:35vh;
  height:auto;
  -webkit-transition:.5s;
  transition:.5s;
  border-radius:4px;
}

#graderNav:hover .graderImage{
  -webkit-filter:blur(2px) brightness(60%);
          filter:blur(2px) brightness(60%);
}

#domainFinderNav:hover .domainFinderImage{
  -webkit-filter:blur(2px) brightness(60%);
          filter:blur(2px) brightness(60%);
}

#sleepClockNav:hover .sleepClockImage{
  -webkit-filter:blur(2px) brightness(60%);
          filter:blur(2px) brightness(60%);
}

.textSection{
  font-family: "Montserrat";
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position:absolute;
  z-index:1;
  top:15%;
  left:7.5%;
  right:7.5%;
  width:85%;
  text-align:center;
  color:#fff;
  opacity:0;
  -webkit-transition:.3s;
  transition:.3s;
}

.navSection h3{
  top:50%
}

.navSection:hover :not(img){
  opacity:1;
}

#projectsHeader{
  font-family: "Montserrat";
  font-size: 250%;
  margin-bottom: 2%;
  -webkit-transition: 2s ease-in-out;
  transition: 2s ease-in-out;
}

.projectList{
    padding-top: 5%;
}
.contactSegment{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 30%;
    margin-right: 30%;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    height: 100%;
}

#contactHeader{
    font-family: "Montserrat";
    font-size: 250%;
    color: grey;
    margin-bottom: 0;
    -webkit-transition: 2s ease-in-out;
    transition: 2s ease-in-out;
  }

#contactForm{
    width: 100%;
    min-width: 45vh;
}

#nameLabel, #emailLabel{
    font-family: "Montserrat";
    color: gray;
}

#contactTray{
    margin-bottom: 50%;
}
#homeSectionButton:hover, #projectsSectionButton:hover, #aboutSectionButton:hover, #contactSectionButton:hover, #resumeNav:hover{
    color:brown;
}

#headerSection{
    z-index:1000;
    background-color: rgb(36, 39, 49);
    height: 6vh;
    width: 100%;
    font-family: "Montserrat";
    top: 0;
    position:-webkit-sticky;
    position:sticky;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.headerDiv{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 auto;
    width: 50%;
    height: 100%;
}

#homeSectionButton, #projectsSectionButton, #aboutSectionButton, #contactSectionButton, #resumeNav{
    background:none!important;
    border:none; 
    font-family: "Montserrat";
    cursor: pointer;
    padding-top: 1vh;
    padding: 2%;
    font-size: 2.5vh;
    outline: none;    
    -webkit-transition: 0.4s ease-in-out;    
    transition: 0.4s ease-in-out;
    color: grey;
    white-space: nowrap;
}
.WelcomeMessageDiv{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    top: 45vh;
    bottom: 50vh;
    left: 30vh;
    right: 30vh;
    color: white;
    box-sizing: border-box;
}

#timeGreeting{
    font-size: 350%;
}
#welcomeMessage{
    font-size: 200%;
}

#welcomeMessagePart2{
    color:brown;
}
.UITop{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.UIMiddle{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 5%;
  margin-bottom: 5%;
}


#modalTrigger{
  font-size: 75%;
  height: 75%;
}

#percToggleButton, #markToggleButton{
  height: 75%;
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

#lightToggleButton, #darkToggleButton{
  max-width: 7vh;
}

.ViewToggleDiv{
  padding: 3%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}


.UIComponents{
  display: -webkit-flex;
  display: flex;
}

.WeightMarkInputs{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 15%;
}

#nameField{
  width: 150%;
}

#markField{
  margin-left: 5%;
}

#markField, #weightField{
  width: 100%;
}

#removeButton{
  height: 100%;
  margin-left: 5%;
}

.UISection{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}


.InputSection{
  min-width: 50vh;
}

.OutputSection{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-right: 20vh;
  width: 45vh;
  min-width: 50vh;
}

#outputFields{
  font-size: 115%;
  margin-top: 0;
  padding-left: 5%;
}

.schemeSelectors{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

button:hover {
  color:red;
}

.progressBar{
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

#bar{
  margin-bottom: 0;
}

.InputOutputSections{
  min-height: 80vh;
  width: 100%;
  padding-left: 30vh;
  padding-top: 13vh;
  padding-bottom: 2vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  justify-items: flex-end;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.GraderDiv{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#pavyonButton{
  max-width: 8vh;
  max-height: 5vh;
  font-size: 10px;
}
.domainFinderDiv{
    height: 80vh;
    background-color: rgb(240, 240, 240);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.inputDiv{
    padding-left: 15%;
    padding-right: 15%;
}

.outputDiv{
    background-color: rgb(230, 230, 230);
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 3%;
    height: 0;
}
#searchButton{
    margin-top: 1%;
}

#domainName{
    font-family: "Montserrat";
}
.errorSection{
    background-color: rgb(36, 39, 49);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-height: 80vh;
}

.errorMessage{
    font-family: "Montserrat";
    color: grey;
    font-size: 325%;
}

.errorPicture{
    height: 45vh;
}
