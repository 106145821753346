.FooterDiv{
    width: 100%;
    background-color: rgb(36, 39, 49);
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0;
}

.FooterInnerDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;}

#gitIcon, #linkIcon, #instaIcon{
    width: 3vh;
    height: 3vh;
    min-height: 1vh;
    min-width: 1vh;
}

#gitNav, #linkNav, #instaNav{
    padding-left: 2vh;
    padding-right: 2vh;
}




#gitNav, #linkNav, #instaNav{
    transition: 0.25s ease-in-out;
    opacity: .7;
}

#gitNav:hover, #linkNav:hover, #instaNav:hover{
    visibility: visible;
    opacity: 1;
}

#footerTray{
    width: 25vh;
}

#footerStartButton{
    background:none!important;
    border:none; 
    font-family: "Montserrat";
    cursor: pointer;
    font-size: 150%;
    outline: none;    
    transition: 0.4s ease-in-out;
    color: grey;
    white-space: nowrap;
    margin-bottom: 2vh;
}


#doubleUpIcon{
    margin-right: 0;
}

#homeNav{
    font-family: "Montserrat";
    cursor: pointer;
    font-size: 130%;
    transition: 0.4s ease-in-out;
    color: grey;
    padding-bottom: 2.5vh;
}

#homeNav:hover{
    color:brown;
}

#footerStartButton:hover{
    transform: scale(1.4);
    color:brown;
}